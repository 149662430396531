import React from 'react'
import PropTypes from 'prop-types'
import { graphql, Link } from 'gatsby'
import Img from 'gatsby-image'
import Layout from '../components/Layout'
import SEO from '../components/SEO'
import HomeCarousel from '../components/HomeCarousel/HomeCarousel'

import "../styles/home.scss"

export const HomeBoxes = ({ images }) => {

  const boxes = [
    {
      title: 'Turnstiles',
      img: 'turnstiles',
      caption: 'Excellent turnstile solutions for all types of situations.',
      to: '/turnstiles'
    },
    {
      title: 'Car Park Barriers',
      img: 'cpb',
      caption: 'Prevent unauthorised access to your site.',
      to: '/car-park-barriers'
    },
    {
      title: 'Biometrics',
      img: 'biometrics',
      caption: 'Fingerprint recognition access control, for absolute peace-of-mind.',
      to: '/biometrics'
    }
  ];

  const content = boxes.map((b) => {
    return (
      <div
        className="column is-one-third"
        key={b.title}
      >
        <div>
          <Img fixed={images[b.img].childImageSharp.fixed} />
          <div className="caption">
            <h4>
              <Link
                to={b.to}
              >
                {b.title}
              </Link>
            </h4>
            <p>{b.caption}</p>
            <Link
              to={b.to}
            >
              More info
            </Link>
          </div>
        </div>
      </div>
    )
  });

  return (
    <div className="home-boxes columns">
      {content}
    </div>
  )
}

export const PageTemplate = ({ title, content, sliderContent, homeBoxImages }) => {
  return (
    <section className="page-wrapper">
      <div className="container">

        <HomeCarousel slides={sliderContent} />

        <div className="columns">
          <div className="column is-10 is-offset-1">
            <div className="section">
              <h2 className="title is-size-3 has-text-weight-bold is-bold-light">
                {title}
              </h2>
              <div
                className="content"
                dangerouslySetInnerHTML={{ __html: content }}
              />
            </div>
          </div>
        </div>

        <HomeBoxes images={homeBoxImages}/>

      </div>
    </section>
  )
}

PageTemplate.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.string,
}

const Page = ({ data }) => {
  const { wordpressPage: page } = data
  const homeBoxImages = {
    turnstiles: data.turnstilesImage,
    cpb: data.cpbImage,
    biometrics: data.biometricsImage,
  }

  return (
    <Layout>
      <SEO
        title={page.title}
      />
      <PageTemplate
        title={page.title}
        content={page.content}
        sliderContent={page.acf.slider}
        homeBoxImages={homeBoxImages}
      />
    </Layout>
  )
}

Page.propTypes = {
  data: PropTypes.object.isRequired,
}

export default Page

export const pageQuery = graphql`
  query HomePageById($id: String!) {
    wordpressPage(id: { eq: $id }) {
      title
      content
      slug
      acf {
        slider {
          text
          url
          image {
            localFile {
              childImageSharp {
                fluid(maxWidth: 1700) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
    turnstilesImage: file(relativePath: { eq: "home-tiles/turnstiles.jpg" }) {
      childImageSharp {
        fixed(width: 307, height: 265) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    cpbImage: file(relativePath: { eq: "home-tiles/car-park-barriers.jpg" }) {
      childImageSharp {
        fixed(width: 307, height: 265) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    biometricsImage: file(relativePath: { eq: "home-tiles/biometrics.jpg" }) {
      childImageSharp {
        fixed(width: 307, height: 265) {
          ...GatsbyImageSharpFixed
        }
      }
    }
  }
`
