import React from 'react'
import { Link } from 'gatsby'
import Img from 'gatsby-image'
import Slider from 'react-slick';

import './HomeCarousel.scss';

class HomeCarousel extends React.Component {

  drawSlides(slides) {
    const slideContent = slides.map((s, i) => {
      return (
        <div key={i}>
          <Img fluid={s.image.localFile.childImageSharp.fluid} />
          <h3
            dangerouslySetInnerHTML={{ __html: s.text }}
          >
          </h3>
          <Link
            className='learn-more-button button is-black'
            to={s.url}
          >
            Learn More
          </Link>
        </div>
      )
    });

    return slideContent;
  }

  render() {
    var settings = {
      autoplay: true,
      autoplaySpeed: 8000,
      arrows: false,
      dots: false,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1
    };
    return (
      <Slider className="home-carousel" {...settings}>
        {this.drawSlides(this.props.slides)}
      </Slider>
    );
  }
}

export default HomeCarousel;
